import {Content} from "../instances/Content";
import {ContentManager} from "./ContentManager";
import {DependencyManager} from "./DependencyManager";
import {XHRManager} from "./XHRManager";

interface ArrayOfPromises{ [key: string]: Promise<Content>; }

export class DownloadManager
{
    private static requests:ArrayOfPromises = {};

    public static download(src:string):Promise<Content>
    {
        return DownloadManager.requests[src] || (DownloadManager.requests[src] = new Promise<Content>(function(resolve, reject){

            XHRManager.requestGet(src).then(function(data)
            {
                let content = new Content(src, data);
                ContentManager.onNewContent(content);
                DependencyManager.resolve(content).then(() => resolve(content)).catch(reject);
            }).catch(reject);

        }));
    }


}