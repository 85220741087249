import {Content} from "../instances/Content";
import {Dependency, DependencyElement, DependencyType} from "../instances/Dependency";
import {ContentManager} from "./ContentManager";


interface ArrayOfPromises{ [key: string]: Promise<any>; }
interface ArrayOfBooleans{ [key: string]: boolean; }

export class DependencyManager
{
    private static queue:ArrayOfPromises = {};
    private static done:ArrayOfBooleans = {};

    public static resolve(content:Content):Promise<any>
    {
        let imports = content.get_imports(); if(imports.isEmpty()) return Promise.resolve();
        let resolved = DependencyManager.isResolved(imports); if(resolved) return Promise.resolve();

        return new Promise(function(resolve, reject)
        {
            //high priority:
            let promises = imports.getRequired().map(d=>DependencyManager.download(d));
            Promise.all(promises).then(resolve).catch(reject);

            //optional low priority:
            imports.getOptional().forEach(d => DependencyManager.download(d));
        });
    }

    private static isResolved(imports:Dependency):boolean
    {
        for(let e of imports.getAll()) {
            let uid = e.get_uid();
            let isDone = !!DependencyManager.done[uid]; if(!isDone)return false;
        }
        return true;
    }


    private static download(dep:DependencyElement):Promise<any>
    {
        let src = dep.get_src();
        switch(dep.get_type())
        {
            case DependencyType.FILE: return DependencyManager.download_file(src);
            case DependencyType.COM: return DependencyManager.download_com(src);
        }
        return Promise.resolve();
    }

    private static download_com(url:string):Promise<any>
    {
        return new Promise<any>(function(resolve, reject){
            ContentManager.get(url).then(resolve).catch(function(){
                reject('Required dependency ('+url+') could not be loaded');
            });
        });
    }

    private static download_file(url:string):Promise<any>
    {
        return DependencyManager.queue[url] || (DependencyManager.queue[url] = new Promise(function(resolve, reject)
        {
            console.log("loading: ", url);

            let ext = (url.replace(/.*?\.(\w+)$/, '$1') || '').toLowerCase();
            let element:any = null;
            let parent = 'head';
            let srcAttr = 'src';
            switch(ext)
            {
                case 'js':
                case 'php':
                    element = document.createElement('script');
                    element.async = true;
                    break;

                case 'css':
                    element = document.createElement('link');
                    element.type = 'text/css';
                    element.rel = 'stylesheet';
                    srcAttr = 'href';
                    parent = 'head';
                    break;

                case 'jpeg':
                case 'jpg':
                case 'gif':
                case 'png':
                case 'svg':

                    element = document.createElement('img');
                    break;
            }
            element.onload = function() { DependencyManager.done[url] = true; resolve(url); };
            element.onerror = function() { reject(url); };
            element[srcAttr] = url;

            // @ts-ignore
            document[parent].appendChild(element);
        }));

    }
}