import {Constants} from "./kernel/Constants";
import {JComDOM} from "./kernel/instances/realworld/JComDOM";
import {MutationManager} from "./kernel/managers/MutationManager";

export class Listener
{
    private static observer:MutationObserver;

    public static START(document:Document):void
    {
        Listener.observer = new MutationObserver(function(mutationsList){
            for(const mutation of mutationsList)
            {
                switch(mutation.type)
                {
                    case 'childList':
                        for(const i in mutation.addedNodes) {
                            const node = mutation.addedNodes[i]; if(!(node instanceof HTMLElement))continue;
                            MutationManager.JCOM_INIT_RECURSIVE(node);
                        }
                        break;

                    case 'attributes':

                        if(mutation.attributeName !== Constants.GET.srcAttr) continue;
                        let node = mutation.target as HTMLElement; if(!node || node.tagName !== 'J') continue;
                        let el = node as JComDOM;
                        let com = el.data;

                        // assuming this stuff runs single threaded
                        if(com && com.expectAttrChange>0){
                            com.expectAttrChange--;
                            continue;
                        }


                        MutationManager.JCOM_DESTROY(el);
                        MutationManager.JCOM_INIT_RECURSIVE(node);
                        break;
                }

            }
        });
        Listener.observer.observe(document, { childList: true, subtree: true, attributes: true });
    }

    public static STOP()
    {
        Listener.observer.disconnect();
    }

}