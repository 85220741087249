import {Listener} from "./JCom/Listener";
import {Constants} from "./JCom/kernel/Constants";
import {JComDOM} from "./JCom/kernel/instances/realworld/JComDOM";
import {StyleManager} from "./JCom/kernel/managers/StyleManager";

function patch_jquery()
{
    // @ts-ignore
    $.fn.com = function(fnCallback:any) {
        this.toArray().forEach(function(dom:JComDOM) {
            JComDOM.init(dom).data.ready.then(fnCallback);
        });
    }
}

function inject_css()
{
    StyleManager.injectStyle(`
        j{ display:none; }
        j.j-ready{ display:inline; }
        j.j-loading{ display:inline; opacity:0.8; }
    `);
}


export function init(): void
{
    if(!$ || !$.fn) console.error('Is jQuery missing !? It seems so.');
    patch_jquery();
    inject_css();

    // @ts-ignore
    window.jCom = function(document, opt)
    {
        // @ts-ignore
        window.t_start = (new Date()).getTime();

        Constants.set(opt);
        Listener.START(document);

        setInterval(function(){
            // @ts-ignore
            let diff = (window.t_last  - window.t_start);
            // @ts-ignore
            if(window.l_diff == diff)return; window.l_diff = diff;
            let txt =  diff+'ms';
            document.title = txt;
            console.log(txt);
        }, 1000);
    };


}