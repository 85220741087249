import {Constants} from "../Constants";
import {ContentManager} from "../managers/ContentManager";
import {Content} from "./Content";
import {Kernel} from "../Kernel";
import {JComDOM} from "./realworld/JComDOM";
import {JComInterface} from "./realworld/JComInterface";

export class JCom
{
    //private $com:JQuery;
    private dom:JComDOM;
    private src:string;

    constructor(element:JComDOM)
    {
        this.dom = JComDOM.init(element);
        this.src = element.getAttribute(Constants.GET.srcAttr)||'';
        this.src = JCom.NORMALISE_SRC(this.src);
        this.init_content();
    }


    private static NORMALISE_SRC(src:string):string{
        return src.trim().toLowerCase(); //TODO improve
    }


    private init_content():void
    {
        const self = this;

        this.dom.data.set_initial(this.dom.innerHTML);

        this.dom.classList.add(Constants.GET.SYSTEM_COM_CLASS_LOADING);
        if(this.src !== '(system)/loading')
            this.dom.innerHTML = '<j src="(system)/loading"></j>';

        ContentManager.get(this.src).then(function(content:Content){
            try {
                self.render(content);
            }finally{
                self.markAsReady();
            }
        }).catch(function(err){
            Kernel.PANIC('"'+self.src+'": ' + err);
        });
    }



    private render(content:Content):void
    {
        let src = this.src;
        let uid = content.get_uid();
        this.dom.classList.add(uid);
        this.dom.classList.remove(Constants.GET.SYSTEM_COM_CLASS_LOADING);
        this.dom.innerHTML = content.get_html();

        try {
            const winKey:any = Constants.GET.windowScriptPrefix + uid;
            // @ts-ignore
            let fn = window[winKey] as any;
            if (fn){
                let instance = new JComInterface(src, this.dom);
                fn.bind(instance)(this.dom);
                this.dom.data.readyControl.resolve(instance);
            }
        }catch(err){ console.error(err); }
        // @ts-ignore
        window.t_last = (new Date()).getTime();
    }
    private markAsReady():void{
        this.dom.classList.add(Constants.GET.SYSTEM_COM_CLASS_READY);
    }
}