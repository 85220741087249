import {Content} from "../instances/Content";
import {ContentManager} from "./ContentManager";
import {DependencyManager} from "./DependencyManager";
import {Kernel} from "../Kernel";
import {Constants} from "../Constants";

export enum RequestType{
    UNKNOWN,
    GET,
    QUERY
}

class Request
{
    private static NEXT_UID:number = 0;

    public promise: Promise<any>;
    public resolved = false;
    public pending = false;
    private doResolve;
    private doReject;
    // ----
    private type:RequestType;
    private uid:number;
    private src:string;
    private data:any;
    private parallel:boolean;

    constructor(type:RequestType, src:string, data:any, parallel:boolean)
    {
        const self = this;
        this.uid = Request.NEXT_UID++;
        this.type = type;
        this.src = src;
        this.data = data;
        this.parallel = parallel;
        this.promise = new Promise<any>(function(resolve, reject){
            self.doResolve = resolve;
            self.doReject = reject;
        });
        this.promise.then(function(){
            self.resolved = true;
        });
    }

    public get_uid():number{ return this.uid; }
    public get_type():RequestType{ return this.type; }
    public is_parallel():boolean{ return this.parallel; }
    public set_pending(){ this.pending = true;}

    public resolve(data:any){
        this.data = data;
        this.resolved = true;
        this.doResolve(data);
    }
    public reject(reason:string){
        this.resolved = true;
        this.doReject(reason);
    }

    public grab(){
        this.set_pending();
        return [this.uid, this.type, this.src, this.data];
    }
}


export class XHRManager
{
    private static requests:Array<Request> = [];
    private static wasInit = false;

    public static request(type:RequestType, src:string, data:any, parallel:boolean):Promise<any>
    {
        XHRManager.init();
        let req = new Request(type, src, data, parallel);
        XHRManager.requests.push(req);
        return req.promise;
    }

    public static requestGet(src:string, parallel:boolean=false):Promise<any> {return XHRManager.request(RequestType.GET, src, 0, parallel);}
    public static requestQuery(src:string, data:any, parallel:boolean=true):Promise<any> {return XHRManager.request(RequestType.QUERY, src, data, parallel);}


    private static flush():void
    {
        if(!XHRManager.requests.length)return;

        let BACKEND_URL = Constants.GET.backend;

        let unresolved = XHRManager.requests = XHRManager.requests.filter(req=>!req.resolved);
        let newRequests = unresolved.filter(req => !req.pending); if(!newRequests.length)return;
        newRequests.forEach(r=>r.set_pending());

        let combinedRequests = newRequests.filter(req => !req.is_parallel());
        let parallelRequests = newRequests.filter(req => req.is_parallel());

        let resolveFor = function(requestList:Array<Request>)
        {
            if(!requestList.length)return;
            $.post(BACKEND_URL, {q: JSON.stringify(requestList.map(r=>r.grab()))}).then(function(replies) {
                requestList.forEach(function(req){
                    let uid = req.get_uid();
                    let reply = replies[uid]; if(!reply) return;
                    let ok = reply[0];
                    let packet = reply[1];
                    ok === true ?
                        req.resolve(packet):
                        req.reject(packet);
                });
            }).fail(function(err:any){
                requestList.forEach(req => req.reject(err.responseText || err));
                Kernel.PANIC(err.responseText || err);
            });
        };

        resolveFor(combinedRequests);
        parallelRequests.forEach(q => resolveFor([q]));
    }

    private static init():void
    {
        if(XHRManager.wasInit)return; XHRManager.wasInit=true;
        setInterval(function(){
            XHRManager.flush();
        }, 50);
    }

}