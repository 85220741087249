export class PathManager
{
    public static resolve(path:string, target:string):string
    {
        let isRelative = path.length>1 && path[0] === '.' && path[1] === '/'; if(!isRelative)return path;
        let isTargetDir = !!target.match(/\/$/);
        let ret = isTargetDir ?
            target + path.substring(2):
            target.replace(/(.*?)\/[^\/]*?$/gm, '$1') + '/' + path.substring(2);
        return ret;
    }
}