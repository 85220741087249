import {JComDOM} from "./JComDOM";
import {JComInterface} from "./JComInterface";
import {XHRManager} from "../../managers/XHRManager";

export class JComSystem
{
    private com:JComInterface;
    private ___debounce:any;
    //private ___timers:any; //for destroy() event

    constructor(com:JComInterface)
    {
        this.com = com;
        this.___debounce = {};
        //this.___timers = {interval: [], timeout: []};
    }


    public get_com():JComInterface{ return this.com; }
    public get_src():string{ return this.com.src; }

    public on():void
    {

    }


    public debounce(code:string, interval:number, fnCallback:any)
    {
        let self = this;
        if(this.___debounce[code]) clearTimeout(this.___debounce[code]);
        this.___debounce[code] = self.setTimeout(function(){
            fnCallback();
        }, interval);

    }

    public query(data:any):Promise<any>
    {
        return this.queryCom(this.get_src(), data);
    }
    public queryCom(src:string, data:any, parallel:boolean=true):Promise<any>
    {
        return new Promise<any>(function(resolve, reject){

            XHRManager.requestQuery(src, data, parallel)
                .then(resolve)
                .catch(reject);

        });
    }


    public setTimeout(fn:any, t:number){ return window.setTimeout(fn,t); }
    public setInterval(fn:any, t:number){ return window.setInterval(fn,t); }

}