import {JComDOM} from "./JComDOM";
import {JComSystem} from "./JComSystem";

export class JComInterface
{
    public src:string;
    public dom:JComDOM;
    public System:JComSystem;



    constructor(src:string, com:JComDOM)
    {
        this.src = src;
        this.dom = com;
        this.System = new JComSystem(this);
    }



}