class JComDOMdata
{
    private dom:HTMLElement;


    public ready:Promise<any>;
    public readyControl:any;

    public expectAttrChange:number = 0;
    public wasInit = false;

    constructor(dom:HTMLElement)
    {
        let self = this;
        this.ready = new Promise((resolve, reject)=>self.readyControl = {resolve, reject});
        this.dom = dom;
    }

    public set_initial(data:string){
        // @ts-ignore
        this.dom.initial = data;
    }
}



export class JComDOM extends HTMLElement
{
    public _instancePromise:any|undefined;
    public _instancePromiseKeys:any|undefined;

    // @ts-ignore
    public data:JComDOMdata;
    public static init(e:JComDOM):JComDOM{
        let alreadyInit = e.data instanceof JComDOMdata; // not redundant !important
        if( alreadyInit ) return e;
        e.data = new JComDOMdata(e);
        return e;
    }
    public static destroy(e:JComDOM):void{
        // @ts-ignore
        e.data = undefined;
    }
}