import {Listener} from "../Listener";

export class Kernel
{
    /** When an error is so bad, that you can't move on anymore */
    public static PANIC(msg:string):void
    {
        let $err = $('<j src="(system)/kernel/panic"></j>').text(msg);

        $('body').append($err);
        console.error(msg);



        setTimeout(function(){
            Listener.STOP();
        }, 1000);



        //alert('FATAL ERROR (Kernel PANIC): ' + msg);
        //alert('You will need to refresh the page !!');
        //window.location.reload(true,true);
    }



}