import {Content} from "./Content";
import {PathManager} from "../managers/PathManager";

export enum DependencyType {
    UNKNOWN,
    FILE,
    COM
}

export class DependencyElement
{
    private required:boolean = false;
    private type:DependencyType = DependencyType.UNKNOWN;
    private src:string;
    constructor(e:HTMLElement, parentSrc:string, required:boolean)
    {
        this.src = (e.innerText||'').trim();
        this.src = PathManager.resolve(this.src, parentSrc);
        this.required = required;
        switch(e.tagName)
        {
            case 'J':
            case 'COM': this.type = DependencyType.COM; break;
            case 'FILE': this.type = DependencyType.FILE; break;
        }
    }

    //TODO: better standarization (of relative urls, trim excess '/' slashes, etc etc...)
    public get_uid():string{ return [this.type, this.src].join('-'); }
    public get_src():string{ return this.src; }
    public get_type():DependencyType{ return this.type; }
    public is_required():boolean{ return this.required; }
    public is_optional():boolean{ return !this.required; }
}

export class Dependency
{
    private list:Array<DependencyElement> = [];
    private content:Content;

    constructor(content:Content) { this.content = content; }

    public add(domElement:HTMLElement):void
    {
        let parentSrc = this.content.get_src();
        let required = Dependency.IS_REQUIRE(domElement);
        let children = Array.from(domElement.children);
        for(let e of children)
            this.list.push(new DependencyElement(<HTMLElement>e, parentSrc, required));
    }

    public isEmpty():boolean{ return this.list.length < 1; }
    public getAll():Array<DependencyElement>{ return this.list; }
    public getRequired():Array<DependencyElement>{ return this.list.filter(e=>e.is_required()); }
    public getOptional():Array<DependencyElement>{ return this.list.filter(e=>e.is_optional()); }

    private static IS_REQUIRE(element:HTMLElement):boolean{
        switch(element.tagName){
            case 'REQUIR':
            case 'REQUIRE':
            case 'REQUIRES':
            case 'REQUIRED':
            case 'REQUIRING':
                return true;
            default:return false;
        }
    }
}
