interface ArrayOfString{ [key: string]: string; }

export class UidManager
{
    private static NEXT_ID:number = 0;
    private static UID_TABLE:ArrayOfString = {};

    public static srcToId(src:string):string{

        let tmp = src.replace(/[^a-zA-Z0-9]/gm, '_'); // only in dev/debug phase, please remove later

        if(!UidManager.UID_TABLE[src]) {
            let nextId = UidManager.NEXT_ID++;
            return UidManager.UID_TABLE[src] = 'JC' + nextId + '_'+tmp;
        }
        return UidManager.UID_TABLE[src];
    }
}