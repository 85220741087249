import {Content} from "../instances/Content";


interface ArrayOfStyle{ [key: string]: StyleBlock; }

class StyleBlock
{
    src:string;
    $style: JQuery;
    content: Content;
    lastStyle:string = '';


    constructor(src:string, content:Content)
    {
        this.src = src;
        this.content = content;
        this.$style = StyleManager.injectStyle();
    }


    public set(content:Content)
    {
        let uid = content.get_uid();
        this.content = content;
        this.$style.attr('data-uid', uid);
        this.update();
    }

    public update():void
    {
        let uid = this.content.get_uid();
        let style = this.content.get_style();

        //if(style === this.lastStyle)return;
        //this.lastStyle = style;

        style = style.replace(/^(\s*)\&(?=\s|\.|\+)/gm, '$1j.'+uid);
        this.$style.html(style);
    }
}

export class StyleManager
{

    private static database:ArrayOfStyle = {};

    public static register(content:Content):void
    {
        let src = content.get_src();
        if(!StyleManager.database[src])
            StyleManager.database[src] = new StyleBlock(src, content);
        StyleManager.database[src].set(content);
    }

    public static injectStyle(css:string|null=null):JQuery{
        let $style = $('<style></style>');
        if(css!=null)$style.html(css);
        return $style.appendTo( $('head') );
    }
}