export class Constants
{
    private static DEFAULT = {

        backend: '/',

        tag: 'J',
        srcAttr: 'src',
        windowScriptPrefix: '',



        SYSTEM_COM_CLASS_READY: 'j-ready',
        SYSTEM_COM_CLASS_LOADING: 'j-loading',
    };
    public static GET = Constants.DEFAULT; // Current



    public static set(data:Object){
        Constants.GET = {...Constants.DEFAULT, ...data};
    }
}
