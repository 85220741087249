import {Content} from "../instances/Content";
import {StyleManager} from "./StyleManager";
import {DownloadManager} from "./DownloadManager";
import {ScriptManager} from "./ScriptManager";


interface ArrayOfContent{ [key: string]: Content; }

export class ContentManager
{
    private static database:ArrayOfContent = {};


    public static get(src:string):Promise<Content>
    {
        const cache = ContentManager.get_cache(src);
        if(cache != null) return Promise.resolve(cache);

        return new Promise<Content>(function(resolve, reject){
            DownloadManager.download(src).then(function(content:Content){
                resolve(content);
            }).catch(function(err){
                reject('Failed downloading: ' + (err||'.'));
            });
        });
    }





    public static get_cache(src:string):Content|null{
        return ContentManager.database[src] || null;
    }
    public static set_cache(src:string, content:Content):void{
        ContentManager.database[src] = content;
    }


    public static onNewContent(content:Content):void
    {
        StyleManager.register(content);
        ScriptManager.register(content);
        ContentManager.set_cache(content.get_src(), content);
    }

}