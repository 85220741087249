import {UidManager} from "../managers/UidManager";
import {Dependency} from "./Dependency";

export class Content
{
    private src:string;
    private uid:string;

    private html:string = '';
    private style:string = '';
    private script:string = '';
    private imports:Dependency = new Dependency(this);


    /*
    constructor(html:string,style:string,script:string)
    {
        this.html = html;
        this.style = style;
        this.script = script;
    }*/

    constructor(src:string, rawData:string)
    {
        this.src = src;
        this.uid = UidManager.srcToId(src);
        this.parse_data(rawData);
    }

    private parse_data(rawData:string)
    {
        const self = this;
        const $jdata = $('<div></div>').append(rawData);


        let doStyle = function(){
            let style = '';
            $jdata.find('>style').each(function(){
                style += $(this).html();
                $(this).remove();
            });
            self.style = style;
        };
        let doScript = function(){
            let script = '';
            $jdata.find('>script').each(function(){
                script += $(this).html();
                $(this).remove();
            });
            self.script = script;
        };

        let doImports = function(){
            $jdata.find('>require,>include').each(function(){
                let tag = $(this)[0];
                self.imports.add(tag);
                $(this).remove();
            });
        };

        doImports();
        doScript();
        doStyle();

        //this.html = $jdata.html(); //whatever is left
        //debugger;

        //let src = self.get_src();
        //let tmp = document.createElement('div');
        //$jdata[0].childNodes.forEach(e => tmp.appendChild(e.cloneNode(true)));
        //debugger;
        this.html = $jdata[0].innerHTML;//$jdata[0];
    }

    public get_uid():string{ return this.uid; }
    public get_src():string{ return this.src; }
    public get_html():string{ return this.html; }
    public get_style():string{ return this.style; }
    public get_script():string{ return this.script; }
    public get_imports():Dependency{ return this.imports; }

}