import {Content} from "../instances/Content";
import {Constants} from "../Constants";


export class ScriptManager
{


    public static register(content:Content):void
    {
        const uid = content.get_uid();
        const script = content.get_script();
        const prefix = Constants.GET.windowScriptPrefix;

        if(!script)return;
        if(!script.match(/^\s*function/))return;

        console.log("setting script of: ", uid);
        eval("window." + prefix + uid + " = " + script);
    }
}
