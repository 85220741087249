import {Constants} from "../Constants";
import {JComDOM} from "../instances/realworld/JComDOM";
import {JCom} from "../instances/JCom";
import {PathManager} from "./PathManager";

export class MutationManager
{

    public static JCOM_INIT_RECURSIVE(node:HTMLElement):void
    {
        if(node.tagName === Constants.GET.tag) MutationManager.JCOM_INIT(node as JComDOM);
        for(let child of node.children) MutationManager.JCOM_INIT_RECURSIVE(child as HTMLElement);
    }

    public static JCOM_INIT(el:JComDOM):void
    {
        JComDOM.init(el);

        if(el.data && el.data.wasInit)return;
        let path = el.getAttribute(Constants.GET.srcAttr);
        let isRelative = path && path.length && path[0] == '.' && path[1] == '/';
        if(isRelative) MutationManager.resolveJcomRelativePath(el);

        console.log("INIT: ", el, path);
        new JCom(el);

        if(el.data) el.data.wasInit = true;
    }
    public static JCOM_DESTROY(el:JComDOM):void
    {
        console.log("----------- DESTROY!!!");

        const tag = Constants.GET.tag.toLowerCase();
        const srcAttr = Constants.GET.srcAttr;
        let src = el.getAttribute(srcAttr)||'';
        JComDOM.destroy(el);
        el.outerHTML = `<${tag} ${srcAttr}="${src}"></${tag}>`;
    }


    private static resolveJcomRelativePath(el:JComDOM)
    {
        const SRC_PARAM = Constants.GET.srcAttr;
        let com = el.data;
        let src = el.getAttribute(SRC_PARAM); if(!src)return;
        let isRelative = src.length>1 && src[0] === '.' && src[1] === '/'; if(!isRelative)return;


        let parent = $(el).parents('j:first')[0] as JComDOM;
        MutationManager.resolveJcomRelativePath(parent);

        let parentSrc = parent.getAttribute(SRC_PARAM) || '';
        let newSrc = PathManager.resolve(src, parentSrc);
        if(com) com.expectAttrChange += 1;
        el.setAttribute(SRC_PARAM, newSrc);
    }
}